import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layout'
import Block from '../components/Block'

export default ({ data }) => {
  return (
    <Layout>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div key={node.id}>
          <Block
            title={node.frontmatter.title}
            content={node.html}
            image={node.frontmatter.image.childImageSharp.fluid}
            imageWidth={node.frontmatter.imageWidth}
            imageOrder={node.frontmatter.imageOrder}
            contentWidth={node.frontmatter.contentWidth}
          />
        </div>
      ))}      
    </Layout>
  )
}

export const query = graphql`
  query blockQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/block/"} },
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title            
            imageWidth
            imageOrder
            contentWidth
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`