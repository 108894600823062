var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

if (viewportWidth > 1199) {
    window.addEventListener("scroll", function (event) {
        var scroll = this.scrollY + 300;
        var offset = scroll / 7.5;
        var images = document.getElementsByClassName("blockImage");
        for (var i = 0; i < images.length; i++) {
            images[i].setAttribute("style", "transform: translate(0,-" + offset + "px);");
        }
    });
}