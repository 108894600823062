import React from 'react'

import Form from './callback'

import headerStyles from "../scss/header.module.scss"

const Content = props => (
    <div className={headerStyles.headerContent}>
        <h1 dangerouslySetInnerHTML={{ __html: props.title }} />
        <div dangerouslySetInnerHTML={{ __html: props.content }} />
        <Form />
    </div>
)

export default Content