import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Row from '../Block/row'

const LogoCol = styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    &:nth-child(n+3) {
        margin: 30px 0 0 0;
    }
    @media (min-width: 768px) {
        flex: 0 0 33.333%;
        max-width: 33.333%;
        &:nth-child(n+3) {
            margin: 0;
        }
        &:nth-child(n+4) {
            margin: 45px 0 0 0;
        }
    }
    @media (min-width:992px) {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
        &:nth-child(n+4) {
            margin: 0;
        }
    }
`

const Logo = props => (
    <LogoCol 
        className="logoBlock"
        style={{ 
            textAlign: 'center',
            filter: 'grayscale(100%)',
            opacity: '0.6'
        }}
    >
        <Img fluid={props.image} alt={props.name} />
    </LogoCol>
)

export default () => {
    return (        
        <StaticQuery
            query={graphql`
                query {
                    wordpress: file(relativePath: { eq: "images/logos/wordpress.png" }) {
                        ...fluidImage
                    }
                    nike: file(relativePath: { eq: "images/logos/nike.png" }) {
                        ...fluidImage
                    }
                    react: file(relativePath: { eq: "images/logos/react.png" }) {
                        ...fluidImage
                    }
                    taskers: file(relativePath: { eq: "images/logos/taskers.png" }) {
                        ...fluidImage
                    }
                    unison: file(relativePath: { eq: "images/logos/unison.png" }) {
                        ...fluidImage
                    }
                    magento: file(relativePath: { eq: "images/logos/magento.png" }) {
                        ...fluidImage
                    }
                }
                fragment fluidImage on File {
                    childImageSharp {
                        fluid(maxHeight: 70) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            `} render={data =>
                <Row>
                    <Logo name="Wordpress" image={data.wordpress.childImageSharp.fluid} />
                    <Logo name="Magento" image={data.magento.childImageSharp.fluid} />
                    <Logo name="ReactJS" image={data.react.childImageSharp.fluid} />
                    <Logo name="Nike Team Sport" image={data.nike.childImageSharp.fluid} />
                    <Logo name="Taskers" image={data.taskers.childImageSharp.fluid} />
                    <Logo name="Unison" image={data.unison.childImageSharp.fluid} />                    
                </Row>
            }
        />
        
    )
}