import React from 'react'
import Link, { navigateTo } from "gatsby-link"

import formStyles from "../scss/form.module.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
            "form-name": form.getAttribute("name"),
            ...this.state
        })
        })
        .then(() => navigateTo(form.getAttribute("action")))
        .catch(error => alert(error));
    };
    render() {
        return (
            <div id="contactForm" className={formStyles.footerForm}>
                <div className={formStyles.title}>
                    <h3>Our results speak for themselves</h3>
                    <p>Find out what all the fuss is about, register your interest today.</p>
                </div>
                <form 
                    name="contact" 
                    method="POST" 
                    action="/thank-you/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="hidden" name="bot-field" />
                    <div className={formStyles.formFields}>
                        <div className={formStyles.third}>
                            <input type="text" name="name" id="name" placeholder="Name"  title="Name" aria-label="Name" className={formStyles.formInput} onChange={this.handleChange} required />
                        </div>
                        <div className={formStyles.third}>
                            <input type="text" name="phone" id="phone" placeholder="Contact Number"  title="Contact Number" aria-label="Contact Number" className={formStyles.formInput} onChange={this.handleChange} required />
                        </div>
                        <div className={formStyles.third}>
                            <input type="email" name="email" id="email" placeholder="Email Address"  title="Email Address" aria-label="Email Address" className={formStyles.formInput} onChange={this.handleChange} required />
                        </div>
                        <div className={formStyles.full}>
                            <textarea id="message" name="message" style={{ minHeight: '240px' }}  title="Details about your project (optional)" aria-label="Details about your project (optional)" className={formStyles.formInput} onChange={this.handleChange} placeholder="Details about your project (optional)"></textarea>
                        </div>
                        <div className={formStyles.full}>
                            <input type="submit" value="Get Started*" className={formStyles.formButton} />
                        </div>
                    </div>
                </form>
                <div className={formStyles.disclaimer}>
                    <p>*Your personal data is safe with us! None of your information will be stored on our website and we will only ever contact you in regards to your project, we will never send you spam or sell your data to third party services. By using our enquiry form you consent to us contacting you via email or over the phone to discuss our services for more information <Link to="/privacy-policy/">view our privacy policy</Link>.</p>
                </div>
            </div>
        )
    }
}