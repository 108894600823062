import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import Logo from './logo'
import Illustration from './illustration'
import Content from './content'

import containerStyles from "../scss/container.module.scss"
import headerStyles from "../scss/header.module.scss"

const Header = () => {
  return (
    <StaticQuery
      query={graphql`
        query headerText {
          file(relativePath: {eq: "components/Header/content/header.md"}) {         
            childMarkdownRemark {
              frontmatter {
                title
              }
              html
            }
          }
        }
      `}
    render={data => (
      <>
        <div className={headerStyles.header}>
          <div className={containerStyles.container}>
            <div style={{
              position: 'relative',
              maxWidth: '240px',
              zIndex: 60
            }}>
              <Logo />
            </div>
            <IllustrationContainer>
              <Illustration />
            </IllustrationContainer>            
            <Content
              title={data.file.childMarkdownRemark.frontmatter.title} 
              content={data.file.childMarkdownRemark.html}
            />                       
          </div>
          <PurplePolygon className="polygon"></PurplePolygon>
          <GreyPolygon className="polygon"></GreyPolygon>
        </div>
      </>
    )}
    />
  )
}

export default Header

const IllustrationContainer = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: -50px;
  width: 45%;
  z-index: 50;
  animation: MoveUpDown 3s linear infinite;
  @media (min-width: 576px) {
    display: block;
  }
  @media (min-width: 768px) {
    top: 110px;
  }
  @media (min-width: 992px) {
    top: -50px;
  }
  @media (min-width: 1200px) {
    top: -30px;
  }
  @media (min-width: 1600px) {
    top: -200px;
    width: 50%;    
  }
`

const PurplePolygon = styled.div`
  background: linear-gradient(285deg, #8E2DE2 0%, #4A00E0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  z-index: 20;
  @media (min-width: 1200px) {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  }
  @media (min-width: 1200px) {
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
  }
  @media (min-width: 1600px) {
    clip-path: polygon(0 0, 100% 0, 100% 60%, 0% 100%);
  }
`

const GreyPolygon = styled.div`
  background: #f9f9f9;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 90%);
  z-index: 10;
  @media (min-width: 1200px) {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 85%);
  }
  @media (min-width: 1600px) {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 80%);
  }
`