import React from 'react'
import { navigateTo } from "gatsby-link";

import formStyles from "../scss/form.module.scss"

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default class Callback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state
            })
        })
            .then(() => navigateTo(form.getAttribute("action")))
            .catch(error => alert(error));
    };
    render() {
        return (
            <div>
                <form 
                    name="callback" 
                    method="POST" 
                    action="/thank-you/"
                    data-netlify="true"
                    data-netlify-honeypot="callback-bot-field"
                    onSubmit={this.handleSubmit}
                >
                    <input type="hidden" name="form-name" value="callback" />
                    <input type="hidden" name="callback-bot-field" onChange={this.handleChange} />
                    <input type="text" name="callbackName" id="callbackName" placeholder="Name" title="Name" aria-label="Name" className={formStyles.formInput} onChange={this.handleChange} required />
                    <input type="email" name="callbackEmail" id="callbackEmail" placeholder="Email Address" title="Email Address" aria-label="Email Address" className={formStyles.formInput} onChange={this.handleChange} required />
                    <button type="submit" className={formStyles.formButton}>Get Started*</button>
                </form>
            </div>    
        )
    }
}