import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Row from './row'

import containerStyles from '../scss/container.module.scss'
import formStyles from "../scss/form.module.scss"

const Block = props => (
    <BlockOuter data-aos="fade-up" data-aos-anchor-placement="top-center">
        <div className={containerStyles.container}>
            <Row>
                <Image 
                    width={props.imageWidth} 
                    order={props.imageOrder}
                    className={'order' + props.imageOrder + ' blockImage' }
                >
                    <Img fluid={props.image} alt={props.title} />
                </Image>
                <Content 
                    width={props.contentWidth}
                    className={'order' + (props.imageOrder === 1 ? 2 : 1) + ' blockContent'}
                >
                    <h2 dangerouslySetInnerHTML={{ __html: props.title }} /> 
                    <div dangerouslySetInnerHTML={{ __html: props.content }} />
                    <Link to="#contactForm" className={formStyles.formButton}>
                        {props.imageOrder === 1 ? 'Sign Me Up' : ( props.imageOrder === 2 ? 'Where do I start?' : 'Get Started') }
                    </Link>
                </Content>
            </Row>
        </div>
    </BlockOuter>
)

export default Block

const BlockOuter = styled.div`
    padding: 60px 0;
    @media (min-width: 1200px) {
        padding: 90px 0;
    }
`

const Image = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 auto 3.75rem;
    position: relative;    
    @media (min-width: 768px) {
       flex: 0 0 80%;
        max-width: 80%; 
    }
    @media (min-width: 992px) {
        flex: 0 0 70%;
        max-width: 70%; 
    }
    @media (min-width: 1200px) {
        flex: 0 0 ${ props => props.width};
        max-width: ${ props => props.width};
        margin: 0;
        top: 200px;
    }        
`

const Content = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: 992px) {
        flex: 0 0 70%;
        max-width: 70%; 
        margin: 0 auto;
    }
    @media (min-width: 1200px) {
        flex: 0 0 ${ props => props.width};
        max-width: ${ props => props.width};
        margin: 0;
    } 
`