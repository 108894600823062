import React from 'react'

const Row = ({ children }) => (
    <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -0.9375rem',
        alignItems: 'center',
        justifyContent: 'space-between'
    }}>
        {children}
    </div>
)

export default Row