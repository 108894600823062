import React from 'react'
import styled from 'styled-components'

import Form from './form'
import Logos from './logos'

import containerStyles from "../scss/container.module.scss"

const Footer = () => (
  <Container>
    <div className={containerStyles.container}>
      <Logos />
      <Form />
    </div>
  </Container>  
)

export default Footer

const Container = styled.div`
  padding: 60px 0;
  @media (min-width: 1200px) {
    padding: 90px 0;
  }
`