import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'

import '../components/scss/general.scss'
import 'aos/dist/aos.css'

if (typeof window !== `undefined` || typeof document !== `undefined`) {
  require('../js/parallax')
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          defaultTitle={data.site.siteMetadata.title}
          titleTemplate="%s | Web Passion"
          meta={[
            { name: 'description', content: `${ data.site.siteMetadata.description }` },
            { name: 'keywords', content: `${data.site.siteMetadata.keywords}` },
            { name: 'google-site-verification', content: 'fy6RH-L9hf0nDyXXbz4EvLQMwsQtMCnWzbnqgLhI_hI' },
            { name: 'msvalidate.01', content: 'C0A2485EEA6EFD90EED78AEFA1B145F5' }
          ]}
        >
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header />
        {children}
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout